import { Svix, EndpointIn, EndpointOut } from "svix";
import { RequestContext, HttpMethod } from "svix/dist/openapi";

import { Iterator } from "@svix/common/hooks/pagination";

export type SinkIn = EndpointIn & SinkType;

export type HttpSink = {
  type: "http";
  url: string;
};

export type RabbitMQSink = {
  type: "rabbitMQ";
  uri: string;
  routingKey: string;
};

export type SQSSink = {
  type: "sqs";
  region: string;
  queueDsn: string;
  accessKey: string;
  secretKey: string;
};

export type EventStreamSink = {
  type: "eventStream";
};

export type SinkType = RabbitMQSink | SQSSink | HttpSink | EventStreamSink;

export type SinkTypeName = "rabbitMQ" | "sqs" | "http" | "eventStream";

export type SinkOut = Omit<EndpointOut, "url"> & SinkType;

export interface ListSinksOut {
  data: SinkOut[];
  iterator: Iterator;
  done: boolean;
}

export class SinksApi {
  private readonly basePath = "/api/v1/app";
  private readonly svix: Svix;

  constructor(svix: Svix) {
    this.svix = svix;
  }

  private async getReqContext(path: string, method: HttpMethod) {
    const requestContext = this.svix._configuration.baseServer.makeRequestContext(
      path,
      method
    );
    requestContext.setHeaderParam("Accept", "application/json, */*;q=0.8");
    const authMethod = this.svix._configuration.authMethods["HTTPBearer"];
    await authMethod?.applySecurityAuthentication(requestContext);
    return requestContext;
  }

  async createSink(appId: string, sinkIn: SinkIn): Promise<SinkOut> {
    const path = `${this.basePath}/${appId}/sink`;
    const requestContext = await this.getReqContext(path, HttpMethod.POST);

    requestContext.setHeaderParam("Content-Type", "application/json");
    requestContext.setBody(JSON.stringify(sinkIn));

    return this.sendRequest(requestContext);
  }

  async listSinks(appId: string): Promise<ListSinksOut> {
    const path = `${this.basePath}/${appId}/sink`;
    const requestContext = await this.getReqContext(path, HttpMethod.GET);
    return this.sendRequest(requestContext);
  }

  async getSink(appId: string, sinkId: string): Promise<SinkOut> {
    const path = `${this.basePath}/${appId}/sink/${sinkId}`;
    const requestContext = await this.getReqContext(path, HttpMethod.GET);
    return this.sendRequest(requestContext);
  }

  private async sendRequest(requestContext: RequestContext) {
    const response = await this.svix._configuration.httpApi
      .send(requestContext)
      .toPromise();

    const body = await response.body.text();
    const bodyJson = body.length > 0 ? JSON.parse(body) : undefined;

    if (200 <= response.httpStatusCode && response.httpStatusCode < 300) {
      return bodyJson;
    } else {
      throw bodyJson;
    }
  }
}
