import {
  Flex,
  Heading,
  IconButton,
  SkeletonText,
  Thead,
  Tr,
  Box,
  Th,
  Tbody,
} from "@chakra-ui/react";
import Replay from "@material-ui/icons/Replay";
import { useForm, useWatch } from "react-hook-form";

import { useSearch } from "@svix/common/hooks/search";
import { capitalize } from "@svix/common/utils";
import {
  DateFilterChoice,
  EARLIEST_DATE_FILTER,
  NOW_FILTER,
  useInitialDateFilter,
} from "@svix/common/widgets/DateFilter/DateFilter";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";
import {
  PageToolbar,
  BreadcrumbItem,
  Breadcrumbs,
} from "@svix/common/widgets/PageToolbar";
import Table from "@svix/common/widgets/Table";
import TableCell from "@svix/common/widgets/TableCell";

import { getSvix } from "src/api";
import { useAppWithPrevIterPagination } from "src/hooks/api";
import { useOrgSettings } from "src/hooks/common";
import { useAppSelector } from "src/hooks/store";
import { isEE } from "src/utils";
import JumpToMessagePopover from "./JumpToMessagePopover";
import MessageRow from "./MessageRow";
import FilterMenu, { FilterType } from "../../components/FilterMenu";

function useInitialEventTypes() {
  const savedVal = useSearch("eventTypes");
  if (!savedVal) {
    return [] as string[];
  }

  return savedVal.split(",");
}

export default function Logs() {
  const user = useAppSelector((state) => state.auth.user)!;
  const { stringsOverrides, hideEventTypes } = useAppSelector(
    (state) => state.embedConfig
  );

  const defaultValues = {
    eventTypes: useInitialEventTypes(),
    before: useInitialDateFilter("before", NOW_FILTER),
    after: useInitialDateFilter("after", EARLIEST_DATE_FILTER),
    channels: useSearch("channels") ?? "",
    tags: useSearch("tags") ?? "",
  };
  const formCtx = useForm({ defaultValues });

  let filterCount = 0;

  const eventTypes = useWatch({
    control: formCtx.control,
    name: "eventTypes",
    defaultValue: defaultValues.eventTypes,
  });
  filterCount += eventTypes.length;

  const beforeDateFilter: DateFilterChoice = useWatch({
    control: formCtx.control,
    name: "before",
    defaultValue: defaultValues.before,
  });
  if (beforeDateFilter.value !== NOW_FILTER.value) {
    filterCount++;
  }

  const afterDateFilter: DateFilterChoice = useWatch({
    control: formCtx.control,
    name: "after",
    defaultValue: defaultValues.after,
  });
  if (afterDateFilter.value !== EARLIEST_DATE_FILTER.value) {
    filterCount++;
  }

  const channel = useWatch({
    control: formCtx.control,
    name: "channels",
    defaultValue: defaultValues.channels,
  });
  if (channel) {
    filterCount++;
  }

  const tags = useWatch({
    control: formCtx.control,
    name: "tags",
    defaultValue: defaultValues.tags,
  });
  if (channel) {
    filterCount++;
  }

  const { data: orgSettings } = useOrgSettings();

  const [messages, messagesCtx] = useAppWithPrevIterPagination(
    [
      "messages",
      eventTypes.join(","),
      beforeDateFilter.value,
      afterDateFilter.value,
      channel,
      tags,
    ],
    async (iterator) => {
      const api = getSvix();
      return api.message.list(user.app.id, {
        iterator,
        eventTypes: eventTypes.length ? eventTypes : undefined,
        channel: channel || undefined,
        before: beforeDateFilter.getDate(),
        after: afterDateFilter.getDate(),
        tag: tags || undefined,
      });
    }
  );

  const filters: FilterType[] = ["date"];
  if (!hideEventTypes) {
    filters.push("eventType");
  }
  if (orgSettings?.enableChannels) {
    filters.push("channels");
  }
  if (!isEE) {
    filters.push("tags");
  }

  return (
    <>
      <MetaTitle path={["Logs", user.app.name]} />
      <PageToolbar>
        <Breadcrumbs>
          <BreadcrumbItem>Message Logs</BreadcrumbItem>
        </Breadcrumbs>
      </PageToolbar>
      <Flex alignItems="center" mb={3}>
        <Heading as="h2" size="sm">
          Latest Messages
        </Heading>
        <Flex flexGrow={1} />
        <IconButton
          mr={2}
          size="sm"
          aria-label="Refresh"
          variant="toolbar"
          isLoading={messagesCtx.isFetching}
          onClick={messagesCtx.refetch}
        >
          <Replay style={{ fontSize: 16 }} />
        </IconButton>
        <FilterMenu
          filters={filters}
          control={formCtx.control}
          filterCount={filterCount}
        />
        <Box ml={2}>
          <JumpToMessagePopover />
        </Box>
      </Flex>
      <Table
        data-cy="messages-table"
        response={messages}
        requestElems={messagesCtx}
        variant="hover"
        emptyState="No messages received yet"
        hasPrevIter
      >
        <Thead>
          <Tr>
            {!hideEventTypes && <Th>Event Type</Th>}
            <Th>Msg ID</Th>
            {orgSettings?.enableChannels && (
              <Th>{capitalize(stringsOverrides.channelsMany)}</Th>
            )}
            <Th>Timestamp</Th>
          </Tr>
        </Thead>
        <Tbody>
          {messages?.data.map((msg) => (
            <MessageRow
              key={msg.id}
              msg={msg}
              showChannels={!!orgSettings?.enableChannels}
            />
          ))}
          {!messages && (
            <Tr>
              {!hideEventTypes && (
                <TableCell>
                  <SkeletonText noOfLines={1} />
                </TableCell>
              )}
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
              <TableCell>
                <SkeletonText noOfLines={1} />
              </TableCell>
            </Tr>
          )}
        </Tbody>
      </Table>
    </>
  );
}
