import { useEffect } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import { inIframe } from "@svix/common/utils";
import { MetaTitleProvider } from "@svix/common/widgets/MetaTitle";

import MainRouter from "./MainRouter";
import { RouteResolver } from "./routes";
import "iframe-resizer/js/iframeResizer.contentWindow.min.js";

export const routeResolver = new RouteResolver({
  app: {
    _base: ":appId",
    home: "",
    activity: {},
    endpoints: {
      new: {
        _base: "",
        _id: {
          _base: ":integrationId",
        },
      },
      polling: {
        _id: {
          _base: ":sinkId",
        },
      },
      _id: {
        _base: ":endpId",
        transformation: {},
      },
    },
    sinks: {
      _id: {
        _base: ":endpId",
      },
    },
    "event-types": {},
    integrations: {},
    messages: {
      _base: "",
      _id: {
        _base: ":msgId",
      },
    },
    reports: {},
    settings: {},
  },
  login: {},
  oauth: {
    slack: {
      authorize: {},
    },
    discord: {
      authorize: {},
    },
    hubspot: {
      authorize: {},
    },
    inngest: {
      webhook: {},
    },
    windmill: {
      webhook: {},
    },
  },
});

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      retryOnMount: false,
      staleTime: 1000,
      cacheTime: Infinity,
      notifyOnChangeProps: "tracked",
    },
  },
});

export default function App() {
  useEffect(() => {
    // Make the page take the full vertical space when in standalone mode
    if (!inIframe()) {
      document.documentElement.style.height = "100%";
      document.body.style.height = "100%";
      document.getElementById("root")!.style.minHeight = "100%";
    }
  }, []);

  // DO NOT use `useAppSelector` here. `useStateSelector` can be used instead.
  return (
    <MetaTitleProvider base="Svix App Portal">
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <MainRouter />
        </BrowserRouter>
      </QueryClientProvider>
    </MetaTitleProvider>
  );
}
