import { EnvironmentSettingsApi } from "svix/dist/openapi";

import { getSvix } from "src/api";
import { useAppQuery } from "./api";

export const useOrgSettings = () => {
  return useAppQuery(
    ["orgSettings"],
    async () => {
      const sv = getSvix();
      const config = sv._configuration;
      const api = new EnvironmentSettingsApi(config);
      return api.v1EnvironmentGetSettings({});
    },
    {
      // since org settings should not change frequently, don't refetch on re-mount
      staleTime: Infinity,
    }
  );
};
