import { useRef, useState, useCallback, useEffect } from "react";
import { useQuery } from "react-query";
import type { HttpErrorOut } from "svix";

import { useSearch, setSearch } from "./search";

export interface IRequestElems {
  isLoading: boolean;
  isFetching: boolean;
  isLoadingNext: boolean;
  isLoadingPrev: boolean;
  error: HttpErrorOut | null;
  pageNumber: number;
  nextPage: () => void;
  previousPage: () => void;
  refetch: () => void;
}

export type Iterator = string | undefined | null;

export interface FastApiResponse {
  done: boolean;
  iterator?: Iterator;
  data?: unknown;
}

export interface FastApiWithPrevResponse extends FastApiResponse {
  prevIterator?: Iterator;
}

export interface IListParams {
  iterator?: Iterator;
  limit?: number;
}

function getParamKey(key: string | string[]): string {
  if (typeof key === "string") {
    return key;
  }
  return key.join(".");
}

function getQueryKey(key: string | string[], additionalKey?: string): string[] {
  const ret = [];
  if (typeof key === "string") {
    ret.push(key);
  } else {
    ret.push(...key);
  }

  if (additionalKey) {
    ret.push(additionalKey);
  }
  return ret;
}

export interface IPaginationOpts {
  onError?: (err: any) => void;
}

export default function usePagination<T extends FastApiResponse>(
  key: string | string[],
  request: (iterator: Iterator) => Promise<T>,
  opts?: IPaginationOpts
): [T | undefined, IRequestElems] {
  const isPagingForward = useRef<boolean>();
  const paramKey = getParamKey(key);
  const initialIterator = useSearch(paramKey);
  const initialStack = initialIterator ? [initialIterator] : [];

  const [iteratorStack, setIteratorStack] = useState<Iterator[]>(initialStack);
  const queryKey = getQueryKey(key, iteratorStack[0] ?? undefined);
  const { isFetching, isLoading, error, data, refetch } = useQuery(
    queryKey,
    () => request(iteratorStack[0]),
    {
      keepPreviousData: isPagingForward.current !== undefined,
      onError: opts?.onError,
      onSuccess: () => {
        isPagingForward.current = undefined;
      },
    }
  );

  const nextPage = useCallback(() => {
    const iter = data?.iterator;
    setSearch(paramKey, iter ?? undefined);
    setIteratorStack([iter, ...iteratorStack]);
    isPagingForward.current = true;
  }, [data, iteratorStack, paramKey]);

  const previousPage = useCallback(() => {
    const stack = iteratorStack.slice(1);
    setSearch(paramKey, stack[0] ?? undefined);
    setIteratorStack(stack);
    isPagingForward.current = false;
  }, [iteratorStack, paramKey]);

  return [
    data as T,
    {
      isLoading,
      isFetching,
      isLoadingNext: isFetching && !!isPagingForward.current,
      isLoadingPrev: isFetching && isPagingForward.current === false,
      error: error as HttpErrorOut,
      pageNumber: iteratorStack.length,
      nextPage,
      previousPage,
      refetch,
    },
  ];
}

interface ModifiedIRequestElems extends IRequestElems {
  isPagingForward?: boolean;
  isFirstPage?: boolean;
}

export function usePaginationWithPrevIter<T extends FastApiWithPrevResponse>(
  key: string | string[],
  request: (iterator: Iterator) => Promise<T>,
  opts?: IPaginationOpts
): [T | undefined, ModifiedIRequestElems] {
  const isPagingForward = useRef<boolean>();
  const paramKey = getParamKey(key);
  const initialIterator = useSearch(paramKey);

  const [iter, setIter] = useState<Iterator>(initialIterator);
  const [firstIterator, setFirstIterator] = useState<Iterator>();
  const queryKey = getQueryKey(key, iter ?? undefined);
  const { isFetching, isLoading, error, data, refetch, isPreviousData, isRefetching } =
    useQuery(queryKey, () => request(iter), {
      keepPreviousData: isPagingForward.current !== undefined,
      onError: opts?.onError,
    });

  useEffect(() => {
    if (firstIterator === undefined && !isPreviousData && !isRefetching) {
      setFirstIterator(data?.prevIterator);
    }
  }, [firstIterator, data, isPreviousData, isRefetching]);

  const nextPage = useCallback(() => {
    const iter = data?.iterator || undefined;
    setSearch(paramKey, iter);
    setIter(iter);
    isPagingForward.current = true;
  }, [data, paramKey]);

  const previousPage = useCallback(() => {
    const iter = data?.prevIterator || undefined;
    setSearch(paramKey, iter);
    setIter(iter);
    isPagingForward.current = false;
  }, [data, paramKey]);

  return [
    data as T,
    {
      isLoading,
      isFetching,
      isLoadingNext: isFetching && !!isPagingForward.current,
      isLoadingPrev: isFetching && isPagingForward.current === false,
      error: error as HttpErrorOut,
      pageNumber: 0,
      nextPage,
      previousPage,
      refetch,
      isPagingForward: isPagingForward.current,
      isFirstPage: firstIterator === data?.prevIterator,
    },
  ];
}
