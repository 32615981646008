import { Box, ButtonGroup, Tooltip } from "@chakra-ui/react";
import { Info } from "@material-ui/icons";
import { capitalize } from "lodash";
import { EndpointIn, EndpointOut } from "svix";

import useUpdateMutation from "@svix/common/hooks/mutate";
import Button from "@svix/common/widgets/Button";
import Form, { GeneralFormErrors } from "@svix/common/widgets/Form";
import Stat from "@svix/common/widgets/Stat";
import SubmitButton from "@svix/common/widgets/SubmitButton";

import { getSvix } from "src/api";
import { useAppSelector } from "src/hooks/store";
import ReadOnlyTooltip from "src/widgets/ReadOnlyTooltip";
import ChannelsAutocomplete from "./ChannelsAutosuggest";

interface IChannelsProps {
  endpoint: EndpointOut;
  queryKey: string[];
}

export const MAX_CHANNELS = 10;

export default function Channels(props: IChannelsProps) {
  const { endpoint, queryKey } = props;
  const user = useAppSelector((state) => state.auth.user)!;
  const stringsOverride = useAppSelector((state) => state.embedConfig.stringsOverrides);

  const isReadOnly = useAppSelector((state) => state.embedConfig.isReadOnly);
  const { edit, cancelEdit, formCtx, mutate, isEditing, isSubmitting } =
    useUpdateMutation<EndpointOut>(queryKey, endpoint, "channels", async (form) => {
      const api = getSvix();
      if (form.channels && form.channels.length === 0) {
        delete form["channels"];
      }
      return api.endpoint.update(user.app.id, endpoint.id, form as EndpointIn);
    });

  return (
    <Form onSubmit={mutate} {...formCtx}>
      <Stat
        name={
          <Tooltip label={stringsOverride.channelsHelp}>
            <Box display="flex" alignItems="center">
              {capitalize(stringsOverride.channelsMany)}
              <Info
                style={{
                  marginLeft: "0.4rem",
                  fontSize: "1rem",
                  opacity: 0.3,
                }}
              />
            </Box>
          </Tooltip>
        }
        cta={
          isEditing ? (
            <ButtonGroup ml={2} size="xs">
              <Button colorScheme="gray" onClick={cancelEdit}>
                Cancel
              </Button>
              <SubmitButton key="save" isLoading={isSubmitting}>
                Save
              </SubmitButton>
            </ButtonGroup>
          ) : (
            <ReadOnlyTooltip readOnly={isReadOnly}>
              <Button
                isDisabled={isReadOnly}
                type="button"
                size="xs"
                colorScheme="gray"
                ml={2}
                onClick={edit}
                key="edit"
              >
                Edit
              </Button>
            </ReadOnlyTooltip>
          )
        }
      >
        {isEditing ? (
          <ChannelsAutocomplete name="channels" control={formCtx.control} />
        ) : endpoint.channels ? (
          <Box>
            {endpoint.channels
              .sort((a, b) => a.localeCompare(b))
              .map((eventType) => (
                <Box key={eventType}>{eventType}</Box>
              ))}
          </Box>
        ) : (
          "None"
        )}
      </Stat>
      <Box mt={1}>
        <GeneralFormErrors />
      </Box>
    </Form>
  );
}
