import { Heading, Stack, Link as StyledLink, Text } from "@chakra-ui/react";
import { Step } from "react-joyride";
import { useHistory } from "react-router-dom";

import * as C from "@svix/common/constants";

import ScreenTour from "./ScreenTour";

export default function EndpointDetailsTour() {
  const history = useHistory();
  const dashboardTourSteps: Step[] = [
    {
      target: "#endpoint-messages-list table",
      content: (
        <>
          <Text>
            Webhook consumers have full visibility into all the messages coming into Svix.
          </Text>
          <Text mt={2}>
            This allows them to debug issues and replay failed messages on their own,
            without needing to contact your team.
          </Text>
        </>
      ),
      placement: "auto",
      disableBeacon: true,
      data: {
        next: () => {
          history.push("#advanced");
        },
      },
    },
    {
      target: "#advanced-tab-content",
      content: (
        <>
          <Heading as="h2" size="sm">
            Advanced Configuration
          </Heading>
          <Text mt={2}>
            Webhook consumers can set custom headers, transform payloads, and set other
            configurations for the delivery of webhooks.
          </Text>
        </>
      ),
      placement: "auto",
      data: {
        next: () => {
          history.push("#testing");
        },
      },
    },
    {
      target: "#testing-tab-content",
      content: (
        <>
          <Heading as="h2" size="sm">
            Webhook Testing
          </Heading>
          <Text mt={2}>
            Test webhook delivery from within the application portal to give your webhook
            consumers a smoother setup process, decreasing onboarding time and integration
            trial-and-error.
          </Text>
        </>
      ),
    },
    {
      target: "#event-catalog-nav-item",
      content: (
        <>
          <Text>
            The Event Catalog shows all the event types your webhook consumers can
            subscribe to. You fully control which events can be sent, and their schema.
          </Text>
          <Text mt={2}>Learn more about event types in</Text>
          <StyledLink
            as="a"
            display="inline"
            textDecoration="underline"
            href={C.docs.quickstart.eventTypes}
            target="_blank"
            isExternal
            color="brand.500"
          >
            the event types docs.
          </StyledLink>
        </>
      ),
      placement: "right",
    },
    {
      target: "body",
      content: (
        <Stack spacing={2}>
          <Heading as="h2" size="sm">
            This is just the beginning!
          </Heading>
          <Text>
            We've built even more to make your life easier, and we'd love to help you
            unlock our product's full potential.
          </Text>
          <Text>
            Chat with one of our engineers{" "}
            <StyledLink
              as="a"
              display="inline"
              textDecoration="underline"
              href={C.bookDemoUrl}
              target="_blank"
              isExternal
              color="brand.500"
            >
              here
            </StyledLink>{" "}
            or join the conversation in our{" "}
            <StyledLink
              as="a"
              textDecoration="underline"
              href={C.slackUrl}
              target="_blank"
              isExternal
              color="brand.500"
            >
              Slack Community
            </StyledLink>
            .
          </Text>
        </Stack>
      ),
      placement: "center",
      locale: {
        last: "Done",
      },
    },
  ];

  return (
    <>
      <ScreenTour tourName="endpoint" isDashboardTour steps={dashboardTourSteps} />
    </>
  );
}
