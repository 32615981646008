import { useEffect } from "react";
import { useTheme } from "@chakra-ui/react";
import Joyride, { ACTIONS, CallBackProps, EVENTS, STATUS, Step } from "react-joyride";
import { useDispatch } from "react-redux";

import { useStateSelector } from "src/hooks/store";
import {
  finishTour,
  setTourStep,
  resumeTour,
  pauseTour,
  setTourInactive,
  ITourName,
} from "src/store/tour";

interface IScreenTourProps {
  tourName: ITourName;
  isDashboardTour?: boolean;
  steps: Step[];
}

// A wrapper around react-joyride that uses the app portal theming and the tour state from the store.
export default function ScreenTour({
  tourName,
  isDashboardTour = false,
  steps,
}: IScreenTourProps) {
  const dispatch = useDispatch();
  const { userTourActive, dashboardTourActive, tours } = useStateSelector(
    (state) => state.tour
  );
  const { done, run, tourStep } = tours[tourName];
  const theme = useTheme();

  const tourActive = isDashboardTour ? dashboardTourActive : userTourActive;

  useEffect(() => {
    if (!done && tourActive) {
      dispatch(resumeTour(tourName));
    }
  }, [done, dispatch, tourActive, tourName]);

  const handleCallback = (data: CallBackProps) => {
    const { action, index, status, type, step } = data;

    if (action === ACTIONS.STOP) {
      dispatch(pauseTour(tourName));
    } else {
      if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
        if (step.data?.next) {
          step.data.next();
        }
        dispatch(
          setTourStep({ tourName, tourStep: index + (action === ACTIONS.PREV ? -1 : 1) })
        );
      } else if (status === STATUS.SKIPPED) {
        dispatch(setTourInactive());
      } else if (status === STATUS.FINISHED) {
        dispatch(finishTour(tourName));
      }
    }
  };

  return (
    <Joyride
      callback={handleCallback}
      stepIndex={tourStep}
      continuous
      run={run && tourActive}
      showSkipButton
      hideCloseButton
      steps={steps}
      locale={{
        last: "Ok",
        skip: "Skip tour",
      }}
      debug={process.env.NODE_ENV === "development"}
      spotlightClicks
      styles={{
        options: {
          arrowColor: "var(--chakra-colors-background-primary)",
          backgroundColor: "var(--chakra-colors-background-primary)",
          textColor: "var(--chakra-colors-text-primary)",
          primaryColor: "var(--brand-500)",
          beaconSize: 32,
        },
        spotlight: {
          borderRadius: theme.radii.card,
          boxShadow: "0 0 6px #fff",
        },
        buttonNext: {
          padding: "8px 16px",
          borderRadius: theme.radii.button,
          fontWeight: 600,
        },
        buttonBack: {
          padding: "8px 16px",
          fontWeight: 600,
        },
        buttonSkip: {
          padding: "0",
        },
        tooltip: {
          padding: "24px",
          borderRadius: theme.radii.card,
        },
        tooltipContent: {
          padding: "0 0 16px 0",
          textAlign: "left",
        },
      }}
    />
  );
}
