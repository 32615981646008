import { Box, Heading, HStack, Text, Image, Stack } from "@chakra-ui/react";
import { Step } from "react-joyride";

import ScreenTour from "./ScreenTour";
import appPortalImg from "../../img/onboarding_management_ui.svg";

export default function EndpointsTour() {
  const dashboardTourSteps: Step[] = [
    {
      target: "body",
      content: (
        <HStack spacing={8}>
          <Box>
            <Heading as="h2" size="md">
              This is the App Portal
            </Heading>
            <Text mt={2}>
              It's a white labeled, pre-built UI that you can embed in your own product
              with one line of code.
            </Text>
            <Text mt={2}>
              Here, your users have full self-serve configurability to register endpoints,
              filter events, debug logs, replay failed events, set rate limits and more.
            </Text>
          </Box>
          <Image
            alignSelf="center"
            borderRadius="md"
            src={appPortalImg}
            height="12em"
            width="12em"
          />
        </HStack>
      ),
      placement: "center",
      disableBeacon: true,
      styles: { tooltip: { width: "36em" } },
    },
    {
      target: "#endpoints-list",
      content: (
        <Stack spacing={2}>
          <Text>
            Here is an example endpoint. Endpoints are where webhooks are sent to. Your
            webhook consumers can create multiple endpoints, and decide which events will
            be sent to which.
          </Text>
          <Text>Click on the endpoint to view the logs and configuration options.</Text>
        </Stack>
      ),
      placement: "auto",
    },
  ];

  return (
    <>
      <ScreenTour tourName="endpoints" isDashboardTour steps={dashboardTourSteps} />
    </>
  );
}
