import * as React from "react";
import {
  FormControl,
  FormHelperText,
  FormLabel,
  Select,
  SelectProps,
} from "@chakra-ui/react";
import { useController } from "react-hook-form";

export interface ISelectFieldProps extends SelectProps {
  control: any;
  name: string;
  label?: React.ReactNode | string;
  helpText?: React.ReactNode | string;
}

export default function SelectField({
  children,
  control,
  name,
  helpText,
  label,
  isRequired,
  ...selectProps
}: ISelectFieldProps) {
  const {
    field: { ref, ...controlProps },
    fieldState: { invalid, error },
  } = useController({
    name,
    control,
    rules: { required: isRequired },
    defaultValue: "",
  });

  return (
    <>
      <FormControl id={name} isInvalid={invalid}>
        {label !== undefined && <FormLabel>{label}</FormLabel>}
        <Select
          variant="filled"
          required={isRequired}
          {...selectProps}
          {...controlProps}
          value={controlProps.value ?? ""}
        >
          {children}
        </Select>

        {!error && helpText && (
          <FormHelperText id={name} color="text.muted">
            {helpText}
          </FormHelperText>
        )}
        {error && <FormHelperText id={name}>{error.message}</FormHelperText>}
      </FormControl>
    </>
  );
}
