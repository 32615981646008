import {
  Box,
  Text,
  Heading,
  Skeleton,
  SkeletonText,
  Stack,
  Tooltip,
  Tag,
  Flex,
  Link,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
} from "@chakra-ui/react";
import { EventTypeOut } from "svix";

import { DotNotationGroup, getGroupedEventTypes } from "@svix/common/utils";
import Card from "@svix/common/widgets/Card";
import SchemaPreviewer from "@svix/common/widgets/JsonSchema/SchemaPreviewer";
import { isSchemaConfigured } from "@svix/common/widgets/JsonSchema/SchemaPreviewer/utils";
import Markdown from "@svix/common/widgets/Markdown";
import { MetaTitle } from "@svix/common/widgets/MetaTitle";

import { useAllEventTypes } from "src/hooks/api";
import { useAppSelector } from "src/hooks/store";
import { useIdsObserver } from "src/utils";
import EmptyState from "./EmptyState";

interface IEventTypePreviewProps {
  eventType: EventTypeOut;
}

function EventTypePreview(props: IEventTypePreviewProps) {
  const { eventType } = props;
  const schema = eventType.schemas?.["1"];

  return (
    <AccordionItem w="100%">
      {({ isExpanded }) => (
        <>
          <AccordionButton w="100%">
            <Box textAlign="left" mr="auto" pl={2}>
              <Heading
                as="h2"
                id={eventType.name}
                size="sm"
                mb={1}
                style={{ scrollMargin: "80px" }}
              >
                {eventType.name}
                {eventType.deprecated && (
                  <Tooltip
                    hasArrow
                    label="This event type may be removed in the future and should no longer be used."
                  >
                    <Tag
                      size="sm"
                      colorScheme="yellow"
                      variant="outline"
                      ml={2}
                      fontWeight="semibold"
                    >
                      Deprecated
                    </Tag>
                  </Tooltip>
                )}
              </Heading>
              <Text variant="caption" whiteSpace="pre-wrap" fontSize="sm">
                <Markdown>{eventType.description}</Markdown>
              </Text>
              <input
                style={{ width: 0, height: 0, overflow: "hidden", display: "flex" }}
                type="button"
                name="event-types-list"
                id={`event-types-focus-input-${eventType.name}`}
              />
            </Box>
            <AccordionIcon mr={1} />
          </AccordionButton>
          <AccordionPanel>
            <Box pl={2}>
              {/* Only render when expanded for efficiency. The SchemaPreviewer is expensive. */}
              {isExpanded && (
                <>
                  {isSchemaConfigured(schema) ? (
                    <SchemaPreviewer
                      schema={eventType.schemas?.["1"]}
                      version={1}
                      autogenerateExample
                    />
                  ) : (
                    <Text variant="caption">No schema available</Text>
                  )}
                </>
              )}
            </Box>
          </AccordionPanel>
        </>
      )}
    </AccordionItem>
  );
}

export default function EventTypesScreen() {
  const user = useAppSelector((state) => state.auth.user)!;
  const { data: eventTypes, isLoading } = useAllEventTypes({
    withContent: true,
    includeArchived: false,
  });
  const activeId = useIdsObserver(eventTypes?.data.map((et) => et.name) || []);
  const { groupedEventTypes } = getGroupedEventTypes(eventTypes?.data || []);

  // ToC is sticky below the navigation bar
  const navigationTabs = document.getElementById("navigation-bar");
  const stickyTop = navigationTabs?.clientHeight ?? 60;

  const hasEventTypes = (eventTypes?.data.length ?? 0) > 0;

  return (
    <>
      <MetaTitle path={["Event Types", user.app.name]} />
      {isLoading ? (
        <>
          <EventTypeSkeleton />
          <EventTypeSkeleton />
          <EventTypeSkeleton />
        </>
      ) : (
        <Flex>
          {hasEventTypes && (
            <Box
              minW="16em"
              pr={4}
              position="sticky"
              top={`${stickyTop}px`}
              height="fit-content"
            >
              <Box>
                <Heading py={4} as="h4" fontSize="lg">
                  Event Types
                </Heading>
              </Box>
              <Card padding={2} bg="transparent">
                <Accordion allowMultiple>
                  {groupedEventTypes.map((gr) => (
                    <TableOfContentsItem
                      key={gr.key}
                      group={gr}
                      eventTypes={eventTypes?.data || []}
                      depth={0}
                      activeId={activeId}
                    />
                  ))}
                </Accordion>
              </Card>
            </Box>
          )}
          <Box my={-8} py={8} w="100%">
            {eventTypes && hasEventTypes && (
              <Accordion allowMultiple>
                {eventTypes.data.map((et) => (
                  <EventTypePreview key={et.name} eventType={et} />
                ))}
              </Accordion>
            )}
            {!hasEventTypes && <EmptyState />}
          </Box>
        </Flex>
      )}
    </>
  );
}

function TableOfContentsItem(props: {
  group: DotNotationGroup;
  eventTypes: EventTypeOut[];
  depth: number;
  activeId: string;
}) {
  const { group, eventTypes, depth, activeId } = props;

  if (group.items.length === 0) {
    const et = eventTypes.find((et) => et.name === group.path)!;
    const isActive = activeId === et.name;

    const handleLinkClick = (e: React.MouseEvent<HTMLAnchorElement>) => {
      e.preventDefault();

      // Nasty hack to 'scroll to' the element, even when in an iframe
      const focusInput = document.getElementById(`event-types-focus-input-${et.name}`)!;
      focusInput.focus();
      focusInput.blur();
    };

    return (
      <Link onClick={handleLinkClick} _hover={{ textDecoration: "none" }}>
        <Box
          py={1}
          my={0.5}
          key={et.name}
          rounded="md"
          _hover={{
            backgroundColor: "background.hover",
            // Let the text bleed out of the box when hovered
            wordBreak: "keep-all",
            overflow: "visible",
            minW: "fit-content",
          }}
          sx={{
            backgroundColor: isActive ? "background.hover" : "transparent",
            wordBreak: "break-all",
            noOfLines: 1,
          }}
        >
          <Text fontSize="sm" color={isActive ? "text.heading" : "text.muted"} px={1}>
            {et.name}
          </Text>
        </Box>
      </Link>
    );
  }

  return (
    <>
      <AccordionItem key={group.key} border={0} rounded="md">
        <AccordionButton
          pt={1.5}
          pb={1.5}
          _hover={{ backgroundColor: "background.hover" }}
          rounded="md"
        >
          <Text
            pl={1}
            as="span"
            flex="1"
            textAlign="left"
            fontSize="sm"
            fontWeight="semibold"
            color="text.heading"
          >
            {group.key}
          </Text>
          <AccordionIcon mr={1} />
        </AccordionButton>
        <AccordionPanel pb={0} pt={0} pl={depth + 1}>
          {group.items.map((item) => (
            <TableOfContentsItem
              key={item.key}
              group={item}
              eventTypes={eventTypes}
              depth={depth + 1}
              activeId={activeId}
            />
          ))}
        </AccordionPanel>
      </AccordionItem>
    </>
  );
}

function EventTypeSkeleton() {
  return (
    <Stack my={6} spacing={4}>
      <Skeleton maxW="20em" height={6} />
      <SkeletonText noOfLines={2} maxW="40em" />
      <Skeleton height="300px" width="100%" />
    </Stack>
  );
}
